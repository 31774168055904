export const backgrounds = [
  "https://res.cloudinary.com/zillaafrica/image/upload/v1649279729/website/Subtract_jtmgbd.png",
  "https://res.cloudinary.com/zillaafrica/image/upload/v1649279826/website/Frame_13501_ftjgak.png",
  "https://res.cloudinary.com/zillaafrica/image/upload/v1649279998/website/Frame_13498_1_gyy4nb.png",
];
export const testomonialText = [
  {
    text: "Paid for some things using Zilla yesterday, love the process. amazing work",
    background: backgrounds[getRandomArbitrary()],
  },
  {
    text: `I really love that I can pay 25% for stuff now and spread the payment without an extra dime. That's new! Love eeeet!`,
    background: backgrounds[getRandomArbitrary()],
  },
  {
    text: `One product I'm really excited about is @usezilla, and I'm going to talk about it every chance I get, so get ready `,
    background: backgrounds[getRandomArbitrary()],
  },
  {
    text: `Visited @usezilla's website and I think whoever designed it needs to be sprayed thousands of dollars while they shake their waist to @StayCavy music `,
    background: backgrounds[getRandomArbitrary()],
  },
  {
    text: "Zilla just works. Kudos to the guys building this.",
    background: backgrounds[getRandomArbitrary()],
  },
  {
    text: `This is by far the best onboarding flow I have experienced for a Nigerian app. So cool`,
    background: backgrounds[getRandomArbitrary()],
  },
];

function getRandomArbitrary() {
  return Math.floor(Math.random() * (3 - 0 + 0)) + 0;
}
