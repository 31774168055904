<template>
  <button @click="$emit('click')">
    <div class="loader" v-if="loading">Loading...</div>
    <div class="button-body" v-else>
      <p>
        {{ text }}
      </p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="20"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M23.883 9.573a.344.344 0 0 1 .047.076.918.918 0 0 1 0 .702.355.355 0 0 1-.048.079 1.193 1.193 0 0 0-.06.095c-.024.041-.047.082-.082.118l-8.93 9.09a.884.884 0 0 1-1.263 0 .92.92 0 0 1 0-1.285l7.406-7.539H6.519A.902.902 0 0 1 5.626 10c0-.502.4-.909.893-.909h14.434l-7.406-7.539a.92.92 0 0 1 0-1.285.88.88 0 0 1 1.263 0l8.93 9.09a.532.532 0 0 1 .081.118l.03.051c.01.017.02.032.032.047ZM.893 9.09h2.21c.493 0 .893.407.893.91 0 .5-.4.908-.893.908H.893A.902.902 0 0 1 0 10c0-.502.4-.909.893-.909Z"
          fill="#fff"
        />
      </svg>
    </div>
  </button>
</template>
<script>
  export default {
    props: {
      text: {
        type: String,
        default: "",
        required: true,
      },
      loading: {
        type: Boolean,
        default: false,
        required: false,
      },
    },
  };
</script>
<style scoped>
  button {
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
    background-color: #c70039;
    border-radius: 10px;
    /* height: 50px; */
    outline: none;
    color: white;
    border: none;
    padding: 0.875rem;
    cursor: pointer;
  }

  svg {
    width: 2rem;
  }
  p {
    margin-right: 2rem;
    font-family: "Aventa", Helvetica;
    font-size: 18px;
    font-weight: 600;
  }
  .button-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .loader,
  .loader:after {
    border-radius: 50%;
    width: 22px;
    height: 22px;
    overflow: hidden;
  }
  .loader {
    overflow: hidden;
    margin: auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 3px solid rgba(255, 255, 255, 0.2);
    border-right: 3px solid rgba(255, 255, 255, 0.2);
    border-bottom: 3px solid rgba(255, 255, 255, 0.2);
    border-left: 3px solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @media (max-width: 768px) {
    p {
      font-size: 14px;
    }
  }
</style>
