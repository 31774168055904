<template>
  <div class="header-container">
    <!-- <div class="blue-circle absolute"></div> -->
    <div class="purple-circle">
      <div class="first-purple-circle"></div>
      <div class="purple-circle second"></div>
    </div>

    <img
      class="first-logo mobile-logo"
      src="https://res.cloudinary.com/zillaafrica/image/upload/v1646029069/merchant-website/Frame_13049_bduajy.svg"
      alt=""
    />
    <img
      src="https://res.cloudinary.com/zillaafrica/image/upload/v1646033862/merchant-website/Hingees-logotype_1_d07pj9.png"
      alt=""
      class="max-h-full second-logo absolute mobile-logo"
    />
    <img
      class="max-h-full third-logo absolute mobile-logo"
      src="https://res.cloudinary.com/zillaafrica/image/upload/v1646034310/merchant-website/gamesole_logo_png_1_2_lj91an.png"
      alt=""
    />
    <img
      class="max-h-full fourth-logo absolute mobile-logo"
      src="https://res.cloudinary.com/zillaafrica/image/upload/v1646035079/merchant-website/Logo_Black_4_2_1_ti7k5l.png"
      alt=""
    />
    <img
      class="max-h-full fifth-logo absolute mobile-logo"
      src="https://res.cloudinary.com/zillaafrica/image/upload/v1646035229/merchant-website/TECHDEUS_Logo_with_type_-centered-_1_1_n0zgyl.png"
      alt=""
    />
    <img
      class="max-h-full sixth-logo absolute mobile-logo"
      src="https://res.cloudinary.com/zillaafrica/image/upload/v1646035784/merchant-website/20210614_224949_0000_1_1_bbmch8.png"
      alt=""
    />
    <!-- <Navbar /> -->
    <div class="header-content">
      <div class="text-center header-content__body">
        <h1>Sell more with Zilla</h1>
        <h2 class="header-description">Earn more . Expand your business</h2>
        <input
          type="text"
          placeholder="Enter email to get started"
          class="email-submit w-full"
          v-model="email"
        />
        <button
          class="header-button w-full"
          @click="$router.push(`/register?email=${email}`)"
        >
          TAKE THE FIRST STEP
        </button>
      </div>
    </div>
  </div>
</template>
<script>
  // import Navbar from "./Navbar.vue";
  export default {
    // components: {
    //   Navbar,
    // },
    data: () => ({
      email: "",
    }),
    mounted() {
      let logoItem = document.querySelectorAll(".mobile-logo");
      // console.log(logoItem);
      logoItem.forEach((item) => {
        item.style.animationDelay = `${Math.random() * (1 - 0) + 0}s`;
      });
    },
  };
</script>
<style scoped>
  .mobile-logo {
    display: none;
    -webkit-animation: bounce 3s infinite ease-in-out;
    -o-animation: bounce 3s infinite ease-in-out;
    -ms-animation: bounce 3s infinite ease-in-out;
    -moz-animation: bounce 3s infinite ease-in-out;
    animation: bounce 3s infinite ease-in-out;
  }
  .header-container {
    overflow: hidden;
    position: relative;
    background: linear-gradient(
      180deg,
      #f7f5ff 0%,
      rgba(247, 245, 255, 0) 100%
    );
  }
  .header-content {
    min-height: 38rem;
    display: grid;
    place-items: center;
  }
  .header-content__body {
    max-width: 570px;
    margin: auto;
    text-align: center;
  }
  .header-content__body h1 {
    font-size: 4.051rem;
    font-weight: bold;
    line-height: 84.5%;
    background-image: linear-gradient(
      217.04deg,
      #c6003b -191.09%,
      #6a30c3 77.43%,
      #63dfdf 156.06%
    );
    background-clip: text;
    padding: 0.3rem;
    color: transparent;
    position: relative;
    z-index: 2;
  }
  .header-description {
    color: #1e1d20;
    font-size: 1.109rem;
    line-height: 120%;
    text-align: center;
    margin-top: 12px;
    font-weight: 400;
  }
  .email-submit {
    background: white;
    border: 2px solid #ebeaee;
    border-radius: 7.11388px;
    /* margin-top: 88px; */
    width: 90%;
    margin: 88px auto 0 auto;
    /* height: 2.819rem; */
    padding: 1.3rem;
    font-size: 1rem;
  }
  .header-button {
    box-shadow: -1px 9px 14px rgba(106, 48, 195, 0.26);
    border-radius: 7.11388px;
    color: white;
    background: linear-gradient(
        217.04deg,
        #c6003b -191.09%,
        #6a30c3 77.43%,
        #63dfdf 156.06%
      ),
      #6a30c3;
    margin-top: 1rem;
    padding: 1.5rem;
    width: 90%;
    margin: 1rem auto 0 auto;
    font-weight: 600;
    font-size: 0.806rem;
    border: none;
    outline: none;
  }

  .first-logo {
    position: absolute;
    left: 6rem;
    top: 10rem;
    max-width: 5.5rem;
  }
  .second-logo {
    top: 23rem;
    left: 3rem;
    max-width: 13.3rem;
  }
  .third-logo {
    right: 10rem;
    max-width: 7.5rem;
    top: 8rem;
  }
  .fourth-logo {
    right: 3.7rem;
    top: 23rem;
    max-width: 9.813rem;
  }
  .fifth-logo {
    left: 15rem;
    bottom: 1rem;
    max-width: 5.438rem;
  }
  .sixth-logo {
    right: 7rem;
    bottom: 4.5rem;
    max-width: 10.813rem;
  }
  .blue-circle {
    height: 5.938rem;
    width: 5.938rem;
    border-radius: 50%;
    top: 4.5rem;
    left: 1rem;
    background-color: #ccffff;
  }
  .first-purple-circle {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    position: absolute;
    right: -30px;
    top: 6rem;
    background: linear-gradient(
        217.04deg,
        #c6003b -191.09%,
        #6a30c3 77.43%,
        #63dfdf 156.06%
      ),
      #6a30c3;
  }
  .purple-circle {
    background: linear-gradient(
        217.04deg,
        #c6003b -191.09%,
        #6a30c3 77.43%,
        #63dfdf 156.06%
      ),
      #6a30c3;
  }

  .purple-circle .second {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    position: absolute;
    top: 10rem;
    left: 20%;
  }

  @media (min-width: 768px) {
    .header-content {
      min-height: 50rem;
    }
    .mobile-logo {
      display: block;
      position: absolute;
    }
    .header-content__body h1 {
      font-size: 126.04px;
    }
    .header-description {
      font-size: 23px;
    }
    .email-submit {
      margin-top: 88px;
      padding: 23.12px;
    }
    .header-button {
      margin-top: 1rem;
      padding: 23px;
      font-size: 23.1201px;
    }
  }
  @-webkit-keyframes bounce {
    0% {
      transform: translateY(-5px);
    }
    50% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(-5px);
    }
  }
  @keyframes bounce {
    0% {
      transform: translateY(-5px);
    }
    50% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(-5px);
    }
  }
</style>
