<template>
  <section class="faq-container" id="faq" ref="faq">
    <h5>Answers to questions others are asking</h5>
    <div class="wrap">
      <button
        class="nav-button prev-button"
        ref="button-right"
        @click="moveRight('left')"
      >
        <svg class="flickity-button-icon" viewBox="0 0 100 100">
          <path
            d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"
            class="arrow"
          ></path>
        </svg>
      </button>
      <div class="wrapper-container" ref="faqWrapper">
        <div class="faq-wrapper">
          <SingleFaq
            v-for="(data, index) in faqData"
            :key="index"
            :data="data"
          />
        </div>
      </div>
      <button
        class="nav-button next-button"
        ref="button-right"
        @click="moveRight('right')"
      >
        <svg class="flickity-button-icon" viewBox="0 0 100 100">
          <path
            d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"
            class="arrow"
            transform="translate(100, 100) rotate(180) "
          ></path>
        </svg>
      </button>
    </div>
  </section>
</template>
<script>
  import SingleFaq from "./singleFaq.vue";
  export default {
    components: {
      SingleFaq,
    },
    data: () => ({
      faqData: [
        {
          title: "What is Zilla",
          image:
            "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1649259545/website/image_82_ddjfko.jpg",
          content:
            "Zilla is a payment solution that enables you to buy what you love now, while you pay later in instalments and at zero interest.",
        },
        {
          title: "How do payments work?",
          image:
            "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1649259625/website/image_86_oljkuz.jpg",
          content:
            "You make an initial payment of 25%, and spread the rest over a period of up to eight weeks. We’ll tell you exactly how much you owe and when it’s due, and send you reminders so you don’t forget.",
        },
        {
          title: "How much interest does Zilla charge?",
          image:
            "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1649259716/website/image_93_n6yyvq.jpg",
          content:
            "We don’t charge our shoppers any interest. That’s right, all your shopping is interest-free!",
        },
        {
          title: "Where can I use Zilla?",
          image:
            "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1649259782/website/image_96_j0kwrw.jpg",
          content:
            "At all your favourite Zilla merchant stores. You can check out a full list on the in the footer section of our website.",
        },
        {
          title: "How do I sign up for Zilla?",
          image:
            "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1648125346/leon-ell-f6HbVnGtNnY-unsplash_1_mruxr7.jpg",
          content:
            "Customers can sign up at customer.usezilla.com. It’s free and only takes 3 mins to get in.",
        },
        {
          title: "How much can I spend on Zilla?",
          image:
            "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1649259827/website/image_98_zkzw6y.jpg",
          content:
            "When you complete your sign up, you will be required to apply for shopping credit. This is a quick 4 step process that will enable us to determine how much we can extend to you to shop. The max amount customers can get in credit at the moment is N300,000.",
        },
        {
          title: "Will Zilla reach out to my contacts for repayments?",
          image:
            "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1649259873/website/image_99_kz4i0p.jpg",
          content:
            "We value the privacy of our customers. We do not have access to your phone contacts and will never request this.",
        },
      ],
      toggleData: true,
    }),
    methods: {
      moveRight(dir) {
        let scrollAmount = 0;
        let slideTimer = setInterval(() => {
          dir === "right"
            ? (this.$refs.faqWrapper.scrollLeft += 15)
            : (this.$refs.faqWrapper.scrollLeft -= 5);
          scrollAmount += 10;
          if (scrollAmount >= 100) {
            window.clearInterval(slideTimer);
          }
        }, 25);
      },
    },
  };
</script>
<style scoped>
  .faq-container {
    padding: 4rem 0;
    background-color: #1e1d20;
    /* overflow: hidden; */
  }
  .faq-container h5 {
    color: white;
    font-size: 1.563rem;
    max-width: 47.875rem;
    margin: auto;
    text-align: center;
    font-weight: 800;
  }
  .faq-wrapper {
    margin-top: 4rem;
    margin-left: 3rem;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    overflow: hidden;
    min-width: 0;
    min-width: 1500px;
    transition: all 0.5s ease;

    /* width: 100vw; */
  }
  .wrapper-container {
    overflow: auto;
    padding-bottom: 0.5rem;
    position: relative;
  }
  .wrap {
    position: relative;
  }
  .nav-button {
    display: none;
  }
  @media (min-width: 768px) {
    .faq-container {
      background-color: white;
    }
    .faq-container h5 {
      font-size: 3.063rem;
      color: #1e1d20;
    }
    .faq-wrapper {
      margin-top: 7rem;
      margin-left: 5rem;
      min-width: 3000px;
      /* align-items: center; */

      /* width: 100vw; */
    }
    .nav-button {
      background: white;
      opacity: 0.8;
      height: 44px;
      width: 44px;
      border-radius: 50%;
      padding: 0.5rem;
      position: absolute;
      top: calc(50% + 1rem);
      z-index: 8;
      display: none;
      cursor: pointer;
      transition: all 0.5s ease-in;
      border: none;
    }
    .next-button {
      right: 2rem;
    }
    .prev-button {
      left: 3rem;
    }
    .wrap:hover .nav-button {
      display: block;
    }
  }

  ::-webkit-scrollbar {
    width: 1px;
  }

  /* Track */
  .faq-wrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
    height: 1px;
    display: none;
  }

  /* Handle */
  .faq-wrapper::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
    height: 1px;
    display: none;
  }

  /* Handle on hover */
  .faq-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
</style>
