<template>
  <div id="app" class="scrollbar">
    <Alert />
    <Navbar />
    <transition name="fadeIn">
      <MobileNav v-if="display" />
    </transition>
    <router-view />
    <Footer />
  </div>
</template>
<script>
  import { mapState } from "vuex";
  import Alert from "@/UI/AlertBox";
  import Footer from "@/UI/Footer";
  import Navbar from "@/UI/Navbar";
  export default {
  //   metaInfo: {
  //   title: 'Shop more Pay later - Zilla',
  //   titleTemplate: '%s | Zilla',
  //   meta: [
  //     { charset: 'utf-8' },
  //     { name: 'description', content: 'Buy anything, anytime and pay later at 0% interest.' },
  //     { name: 'viewport', content: 'width=device-width, initial-scale=1' }
  //   ]
  // },
    components: {
      MobileNav: () => import("@/UI/MobileNav"),
      Alert,
      Footer,
      Navbar,
    },
    computed: {
      ...mapState({
        display: (state) => state.nav.display,
      }),
    },
    watch: {
      display(value) {
        if (value) {
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.overflow = "auto";
        }
      },
    },
  };
</script>
<style>
  @import "./assets/main.css";
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  ::selection {
    background: rgba(105, 86, 200, 0.5); /* WebKit/Blink Browsers */
  }
  ::-moz-selection {
    background: rgba(105, 86, 200, 0.5); /* Gecko Browsers */
  }
  html {
    scroll-behavior: smooth;
  }
  #app {
    font-family: "Gilroy", Helvetica;
    /* overflow-x: hidden; */
  }
  p,
  a,
  span,
  td,
  tr,
  button {
    font-family: Inter, Helvetica;
  }
  .fadeIn-enter-active,
  .fadeIn-leave-active {
    transition: all 0.3s ease-out;
    opacity: 1;
    /* transform: translateX(0); */
  }
  .fadeIn-enter,
  .fadeIn-leave-to {
    opacity: 0;

    /* transform: translateX(10px); */
  }
</style>
