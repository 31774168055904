<template>
  <nav class="navbar-container" :class="{ change_color: navColor }">
    <div class="container navbar-container__body">
      <div class="first-section">
        <div class="logo">
          <router-link to="/">
            <img
              class="max-w-full h-auto"
              src="https://res.cloudinary.com/zillaafrica/image/upload/v1623533492/customer/Zilla_Updated_l9yevg.png"
              alt=""
            />
          </router-link>
        </div>
      </div>
      <div class="harmbugger" @click="toggleNav">
        <div :class="{ 'first-rotate': display }" />
        <div :class="{ 'middle-div': display }" />
        <div :class="{ 'third-rotate': display }" />
      </div>
      <div class="second-section">
        <router-link :to="currentRoute === 'home' ? '/merchant' : '/'">
          <p
            class="second-option_list login"
            :class="{ 'second-section__black': navColor }"
          >
            {{ currentRoute === "home" ? "For Merchant" : "For Customer" }}
          </p>
        </router-link>
        <a href="https://usezilla.com/cardzilla">
          <p
            class="second-option_list login"
            :class="{ 'second-section__black': navColor }"
          >
            CardZilla
          </p></a
        >
        <a href="https://blog.usezilla.com" target="blank">
          <p
            class="second-option_list login"
            :class="{ 'second-section__black': navColor }"
          >
            Blog
          </p></a
        >

        <a href="http://faq.usezilla.com/" target="_blank">
          <p
            class="second-option_list login"
            :class="{ 'second-section__black': navColor }"
            @click.stop="handleFaq"
          >
            FAQs
          </p></a
        >
      <!-- </div> -->
      <!-- <div class="third-section"> -->
        <p
          @click.stop="handleOptions('login')"
          class="second-option_list login"
          :class="{ 'second-section__black': navColor }"
        >
          Login
        </p>
        <div
          class="partition"
          :class="{ 'second-partion__black': navColor }"
        ></div>

        <p
          @click.stop="handleOptions('create')"
          class="second-option_list create-account"
          :class="{ 'second-section__black': navColor }"
        >
          Create account
        </p>
        <transition name="fadeIn">
          <div
            v-click-outside="handleOptionsOutside"
            class="auth-options"
            v-if="showOptions"
            :class="{ change_color: navColor }"
          >
            <div class="options">
              <a
                
                :href="
                  optionValue === 'login'
                    ? `${customerUrl}/login`
                    : `${customerUrl}/register`
                "
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>
                  {{
                    optionValue === "login"
                      ? `Shopper's login`
                      : `Create a Shopper's account`
                  }}
                </p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="20"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M23.883 9.573a.344.344 0 0 1 .047.076.918.918 0 0 1 0 .702.355.355 0 0 1-.048.079 1.193 1.193 0 0 0-.06.095c-.024.041-.047.082-.082.118l-8.93 9.09a.884.884 0 0 1-1.263 0 .92.92 0 0 1 0-1.285l7.406-7.539H6.519A.902.902 0 0 1 5.626 10c0-.502.4-.909.893-.909h14.434l-7.406-7.539a.92.92 0 0 1 0-1.285.88.88 0 0 1 1.263 0l8.93 9.09a.532.532 0 0 1 .081.118l.03.051c.01.017.02.032.032.047ZM.893 9.09h2.21c.493 0 .893.407.893.91 0 .5-.4.908-.893.908H.893A.902.902 0 0 1 0 10c0-.502.4-.909.893-.909Z"
                    fill="#000"
                  />
                </svg>
              </a>
            </div>
            <div class="line"></div>
            <div class="options">
              <a
                :href="
                  optionValue === 'login'
                    ? `${merchantUrl}/login`
                    : `${merchantUrl}/register`
                "
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>
                  {{
                    optionValue === "login"
                      ? `Merchant login`
                      : `Create a Merchant's account`
                  }}
                </p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="20"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M23.883 9.573a.344.344 0 0 1 .047.076.918.918 0 0 1 0 .702.355.355 0 0 1-.048.079 1.193 1.193 0 0 0-.06.095c-.024.041-.047.082-.082.118l-8.93 9.09a.884.884 0 0 1-1.263 0 .92.92 0 0 1 0-1.285l7.406-7.539H6.519A.902.902 0 0 1 5.626 10c0-.502.4-.909.893-.909h14.434l-7.406-7.539a.92.92 0 0 1 0-1.285.88.88 0 0 1 1.263 0l8.93 9.09a.532.532 0 0 1 .081.118l.03.051c.01.017.02.032.032.047ZM.893 9.09h2.21c.493 0 .893.407.893.91 0 .5-.4.908-.893.908H.893A.902.902 0 0 1 0 10c0-.502.4-.909.893-.909Z"
                    fill="#000"
                  />
                </svg>
              </a>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </nav>
</template>
<script>
  import { mapActions, mapState } from "vuex";
  import Button from "@/UI/Button";
  export default {
    components: {
      Button,
    },
    data: () => ({
      scrollPosition: null,
      showOptions: false,
      optionValue: "",
      customerUrl: process.env.VUE_APP_CUSTOMER_URL,
      merchantUrl: process.env.VUE_APP_MERCHANT_URL,
      // display: false,
    }),
    mounted() {
      window.addEventListener("scroll", this.updateScroll);
      // console.log(this.$route, "route");
    },
    computed: {
      ...mapState({
        display: (state) => state.nav.display,
      }),
      navColor() {
        if (this.scrollPosition > 50 || this.showOptions) {
          return true;
        } else {
          return false;
        }
      },
      currentRoute() {
        if (this.$route.path === "/") {
          return "home";
        } else {
          return "merchant";
        }
      },
    },

    methods: {
      ...mapActions("nav", ["toggleNav"]),
      updateScroll() {
        this.scrollPosition = window.scrollY;
      },
      handleOptions(value) {
        this.optionValue = value;
        this.showOptions = !this.showOptions;
      },
      handleOptionsOutside() {
        this.showOptions = false;
      },
    },
  };
</script>
<style scoped>
  .logo {
    display: flex;
    align-items: center;
  }
  .navbar-container {
    display: flex;
    align-items: center;
    height: 5rem;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    transition: all 0.3s ease-in;
  }
  .partition {
    width: 1px;
    height: 22px;
    background-color: #1e1452;
    margin-right: 18px;
    transition: all 0.5s;
  }
  .options a {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    /* padding: 1.875rem 0; */
    width: 100%;
  }
  .first-section {
    display: flex;
    align-items: center;
  }
  .first-section .logo {
    width: 5rem;
    margin-right: 79px;
  }
  .first-section .logo img {
    max-height: 100%;
    max-width: 100%;
  }
  .nav-items {
    display: flex;
    align-items: center;
  }
  .nav-items p {
    color: white;
    font-weight: 600;
    margin-right: 32px;
    cursor: pointer;
  }
  .third-section,
  .second-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* color: white; */
    position: relative;
  }
  .third-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* color: white; */
    position: relative;
  }
  .third-section .second-option_list {
    /* color: white; */
    cursor: pointer;
    /* font-weight: 600; */
    font-size: 12px;
    transition: all 0.3s ease-in;
    color: #1e1452;
  }
  .create-account {
    background: #d5ccff;
    border-radius: 20px;
    padding: 9px 15px;
    /* font-weight: 600; */
    font-size: 12px;
  }
  .second-section__black {
    color: black !important;
    /* background-color: white; */
  }
  .second-partion__black {
    background-color: black !important;
  }
  .second-section .second-option_list {
    /* color: white; */
    cursor: pointer;
    font-size: 12px;


    /* font-weight: 600; */
    transition: all 0.3s ease-in;
    color: #1e1452;
  }

  .login {
    margin-right: 2rem !important;
  }
  .navbar-container__body {
    /* width: 85%; */
    /* margin: auto; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 1rem; */
  }
  .change_color {
    background-color: rgba(255, 255, 255, 0.9) !important;

    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07); */
  }
  .auth-options {
    position: absolute;
    top: 2.9rem;
    background-color: white;
    /* box-shadow: 0px 7px 20px 2px rgba(0, 0, 0, 0.25); */
    width: 19.5rem;
    padding: 0 1.875rem;
    /* z-index: -20 */
    /* z-index: -1 */
  }
  .options a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.063rem 0;
    font-weight: 700;
    cursor: pointer;
  }

  .line {
    /* height: 0.3px; */
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    /* margin: 5px 0; */
    border: 0.1px solid rgba(0, 0, 0, 0.2);
    border-radius: 20px;
  }

  .harmbugger {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 1.6rem;
    border: none;
    cursor: pointer;
    padding: 0;
    /* margin-left: 1rem; */
    left: 1.5rem;
    /* position: fixed; */
    /* top: 1.5rem; */
    z-index: 6;
  }
  .harmbugger:focus {
    outline: none;
  }
  .first-rotate {
    transform: rotate(40deg);
  }
  .third-rotate {
    transform: rotate(-45deg);
  }
  .middle-div {
    opacity: 0;
    transform: translateX(20px);
  }
  @media only screen and (max-width: 768px) {
    .harmbugger {
      display: flex;
    }
    .navbar-container__body {
      width: 90%;
      margin: auto;
    }
  }
  .navbar-container {
    height: 3.938rem;
  }
  .harmbugger div {
    width: 1.6rem;
    height: 2.2px;
    border-radius: 20px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    background: black;
  }
  @media (max-width: 768px) {
    .third-section,
    .second-section {
      display: none;
    }
    .first-section .logo {
      width: 4rem;
    }
  }
  .fadeIn-enter-active,
  .fadeIn-leave-active {
    transition: all 0.3s ease-out;
    opacity: 1;
    /* transform: translateX(0); */
  }
  .fadeIn-enter,
  .fadeIn-leave-to {
    opacity: 0;

    /* transform: translateX(10px); */
  }
  a {
    text-decoration: none;
    color: initial;
  }
</style>
