import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Bare from "../views/bare";
import cookies from "vue-cookies";
import Merchant from "@/views/Merchant/Home";
import Card from '@/views/card'
// import VueMeta from 'vue-meta'


Vue.use(VueRouter);
// Vue.use(VueMeta)


const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/bare",
    name: "Bare",
    component: Bare,
  },
  {
    path: "/merchant",
    name: "Merchant",
    component: Merchant,
    meta: {
      title: 'Sell more with Zilla',
      metaTags: [
        {
          name: 'description',
          content: 'The about page of our example app.'
        },
       
      ]
    },
  },
  {
    path: "/card",
    name: "Card",
    component: Card,
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.query.ref) {
    // set domain
    cookies.set(
      "referralCode",
      to.query.ref,
      null,
      null,
      process.env.VUE_APP_DOMAIN
    );
    next("/");
  }
  next();
});

export default router;
