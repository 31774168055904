import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import nav from "./nav";
import notification from "./notification";

export default new Vuex.Store({
  modules: {
    nav,
    notification,
  },
});
