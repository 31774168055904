<template>
  <div class="faq-item" :style="{ 'background-image': `url(${data.image})` }">
    <div class="faq-circle left__faq-circle"></div>
    <div class="faq-circle right__faq-circle"></div>
    <div class="pick" @click="toggleData = !toggleData">
      <svg
        v-if="toggleData"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#ffffff"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-plus"
      >
        <path d="M12 5v14M5 12h14" />
      </svg>
      <svg
        v-if="!toggleData"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#ffffff"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-minus"
      >
        <path d="M5 12h14" />
      </svg>
    </div>
    <div class="heading">
      <p>{{ data.title }}</p>
    </div>
    <div class="faq-overlay"></div>
    <div class="content-body" :class="{ 'show-content': !toggleData }">
      <p>
        {{ data.content }}
      </p>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      data: {
        type: Object,
        default: () => {},
        required: false,
      },
    },
    data: () => ({
      toggleData: true,
    }),
  };
</script>
<style scoped>
  .faq-item {
    margin-right: 1rem;
    height: 22.313rem;
    background-color: black;
    flex-wrap: nowrap;
    background-position: 0, 0;
    /* background: url("https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1648125346/leon-ell-f6HbVnGtNnY-unsplash_1_mruxr7.jpg"); */
    background-size: cover;
    width: 13rem;
    /* border: 1px solid white; */
    position: relative;
    display: flex;
    align-items: flex-end;
    padding: 1rem;
    overflow: hidden;
    background-position: center;
  }
  .faq-circle {
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    position: absolute;
    background-color: #1e1d20;
    z-index: 8;
    top: calc(50% - 2rem);
    /* bottom: 50%; */
  }

  .right__faq-circle {
    right: -2.9rem;
  }

  .left__faq-circle {
    left: -2.9rem;
  }
  .faq-item .heading {
    color: white;
    font-size: 1.18rem;
    position: relative;
    z-index: 2;
  }
  .faq-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    bottom: 0;
    /* z-index: 2; */
  }
  .pick {
    position: absolute;
    z-index: 10;
    top: 1rem;
    right: 1rem;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: rgba(224, 223, 227, 0.2);
    cursor: pointer;
    padding: 0.3rem;
  }
  .content-body {
    background-color: black;
    color: white;
    padding: 5rem 1rem 1rem 1.4rem;
    position: absolute;
    height: 100%;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 3;
    transform: translateY(100%);
    transition: all 0.5s ease-out;
  }

  .content-body p {
    font-size: 1rem;
  }
  .show-content {
    transform: translateY(0);
  }

  @media (min-width: 768px) {
    .faq-item {
      height: 35.25rem;
      width: 25rem;
    }
    .faq-item .heading {
      font-size: 39px;
    }
    .content-body {
      padding: 6.125rem 2.813rem 1rem 2.813rem;
    }
    .content-body p {
      font-size: 1.68rem;
    }
    .faq-circle {
      height: 8rem;
      width: 8rem;
      border-radius: 50%;
      position: absolute;
      background-color: white;
      z-index: 5;
      top: calc(50% - 4rem);
      /* bottom: 50%; */
    }
    .right__faq-circle {
      right: -6rem;
    }

    .left__faq-circle {
      left: -6rem;
    }
  }
</style>
