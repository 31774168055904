<template>
  <div class="container choice-container mx-auto">
    <div>
      <h3 class="text-darkGrey choice-heading ch">WHY CHOOSE ZILLA</h3>
      <div class="body" v-for="(card, index) in cards" :key="index">
        <div
          class="choice-card relative"
          :style="{
            background: card.background,
            'flex-direction':
              card.direction === 'reverse' ? 'row-reverse' : 'row',
          }"
        >
          <p
            class="background-text"
            v-html="card.name.text"
            :style="{
              bottom: card.name.text.includes('Business') ? '-54rem' : '-20rem',
              color: card.name.color,
            }"
          ></p>
          <figure class="flex items-center relative z-2">
            <img
              loading="eager"
              class="max-h-full max-w-full mx-auto choice-image"
              :src="card.image"
              alt="instant settlement"
            />
          </figure>
          <div class="second-section flex items-center">
            <div
              class="choice-description instant"
              :class="[card.direction === 'reverse' && 'lg:text-right']"
            >
              <div
                class="card-heading"
                v-html="card.name.text"
                :style="{ color: card.name.color }"
              ></div>
              <p
                class="mt-4 description"
                :style="{ color: card.description.color }"
              >
                {{ card.description.text }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data: () => ({
      title: '<p class="background-text">Instant Settlement</p>',
      cards: [
        {
          name: {
            color: "#941980",
            text: "<h2 class=''>Instant <br/> Settlement</h2>",
          },
          image:
            "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1649739192/merchant-website/IS_Q_jnff57.gif",
          description: {
            text: ` You get paid immediately, while your customers pay us at their convenience!`,
            color: "#521448",
          },
          direction: "normal",
          background: "#FFF5FD",
        },
        {
          name: {
            color: "#62DFDF",
            text: "<h2 class='card-heading'>Business & <br/> Marketing Support</h2>",
          },
          image:
            "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1649738732/merchant-website/Bus_Q_1_vui9hv.gif",
          description: {
            text: ` You are not on your own. You will get every bit of support you need to make this partnership run smoothly.`,
            color: "#145252",
          },
          direction: "reverse",
          background: "#F5FFFF",
        },
        {
          name: {
            color: "#C6003B",
            text: "<h2 >Zero risk</h2>",
          },
          image:
            "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1649739354/merchant-website/ZR_Q_wegzhz.gif",
          description: {
            text: `You focus on running your business and we focus on the handling the payment from your customers.`,
            color: "#521427",
          },
          direction: "normal",
          background: "#FFF5F8",
        },
      ],
    }),
  };
</script>
<style scoped>
  .choice-container {
    /* margin-top: 1.438rem; */
    padding: 1.5rem;
  }
  .choice-card {
    overflow: hidden;
    border-radius: 40px;
    /* background-color: #fff5fd; */
    /* display: flex; */
    flex-direction: column;
    min-height: 24.25rem;
    padding: 1.3rem;
    position: relative;
  }

  figure {
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    /* height: 15rem */
  }
  .choice-image {
    /* transform: rotate(4.83); */
    max-width: 192px;
    height: auto;
    transform: scale(1.1)
  }
  .card-heading >>> h2 {
    font-size: 1.875rem;
    line-height: 120%;
    font-weight: 600;
  }

  .choice-description {
    padding: 1rem;
    max-width: 485px;
    margin-top: 23px;
  }

  .instant .card-heading {
    color: #941980;
  }
  .instant .description {
    color: #521448;
    /* font-size: 29px; */
    font-weight: 400;
    font-size: 1.072rem;
    margin-top: 13px;
  }
  .background-text {
    position: absolute;
    /* bottom: -25rem; */
    /* color: #941980; */
    opacity: 0.05;
    font-size: 385.4px;
    line-height: 71.5%;
    font-weight: 700;
  }
  .body {
    margin-top: 5rem;
  }
  .choice-heading {
    text-align: center;
    color: #1e1d20;
  }
  .second-section {
    display: flex;
    align-items: center;
  }

  @media (min-width: 768px) {
    .choice-container {
      margin-top: 10rem;
      padding: 0;
    }

    .choice-card {
      overflow: hidden;
      border-radius: 40px;
      display: flex;
      /* flex-direction: row !important; */
      height: 33.25rem;
      max-width: 73rem;
      margin: 0 auto 10rem auto;
      padding: 1rem 4rem;
    }
    .choice-heading {
      font-size: 3.25rem;
    }
    .card-heading {
      font-size: 52px;
    }
    .instant .description {
      font-size: 29px;
    }
    figure {
      width: 50%;
    }

    .choice-image {
      max-width: 100%;
      max-height: 100%;
    }
    .card-heading >>> h2 {
      font-size: 52px;
    }
  }
</style>
