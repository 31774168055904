<template>
  <div class="integration pb-4">
    <div class="marquee-container">
      <marquee-text :duration="45" :repeat="3" class="marquee">
        <p class="number">1010111111000111</p>
      </marquee-text>
      <marquee-text
        :duration="45"
        :repeat="3"
        reverse
        class="marquee marquee-two"
      >
        <p class="number two-number">1010111111000111</p>
      </marquee-text>
    </div>
    <div class="integrarion-container container mx-auto">
      <div class="description">
        <h3>Integration that works</h3>
        <h2 class="lg:mt-4 mt-2 text">
          You can easily integrate the Zilla payment gateway using our APIs,
          Plugins and SDKs.
        </h2>
        <a
          href="https://usezilla.notion.site/Developers-Documentation-3472cedcf86d47378dec187b32445eb1"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button
            class="
              counter-button
              flex
              items-center
              mt-16
              w-full
              justify-between
            "
          >
            <p class="mr-4">SEE OUR DOCUMENTATION</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="21"
              fill="none"
            >
              <path
                d="M3.20832.30689.88769 2.6275l7.53792 7.55439-7.53792 7.5544 2.32063 2.3206 9.87498-9.875L3.20832.30688Z"
                fill="#6956C8"
              />
            </svg>
          </button>
        </a>
      </div>
      <div class="codes">
        <img
          class="max-w-full h-auto"
          eager
          src="https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1645901367/merchant-website/Group_13001_1_mtlgp8.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script>
  // import gsap from "@/utils/gsap.js";
  import MarqueeText from "./Marquee.vue";
  export default {
    components: {
      MarqueeText,
    },
    mounted() {
      // this.animateDigits();
    },
    methods: {
      handleDoc() {
        alert("hello");
      },
    },
  };
</script>
<style scoped>
  .integration {
    overflow: hidden;
    padding-top: 5rem;
    padding-bottom: 5rem;
    position: relative;
    background: transparent;
    position: relative;
    /* width: 90%; */
    margin: auto;
  }

  .marquee {
    padding-top: 2rem;
    width: 120vw;
  }

  .marquee-container {
    transform: rotate(8.19deg) translateX(-3rem) translateY(3rem);
    top: 0;
    z-index: 5;
    height: 10rem;
  }
  .number {
    color: #1e1d20;
    line-height: 280.36px;
    font-size: 13.938rem;
    font-weight: 900;
    /* letter-spacing: -03rem; */
    font-family: "Gilroy", Helvetica;
  }
  .number-two {
    transform: translateX(-3rem);
  }
  .integrarion-container {
    background: #ffffff;
    border-top-left-radius: 27px;
    border-top-right-radius: 27px;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 40px;
    padding: 1rem;
    margin-top: -50rem;
    position: relative;
    width: 90%;
    margin: auto;
    z-index: 1;

    /* max-width: 70rem; */
    /* height: 45.438rem; */
  }

  .integrarion-container .description {
    font-weight: bold;
    line-height: 73.75px;
    font-size: 2rem;
    text-align: center;
  }
  .integrarion-container .description .text {
    color: #918e9c;
    /* font-weight: 500; */
    font-size: 21.2727px;
    line-height: 150%;
    font-size: 0.875rem;
  }
  .counter-button {
    border: 1px solid #6956c8;
    border-radius: 10px;
    color: #6956c8;
    padding: 16px 24px;
    margin-top: 40px;
    cursor: pointer;
    height: 52px;
    background: white;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* z-index: 99999; */
  }

  .counter-button p {
    font-weight: 600;
  }
  .integrarion-container .codes {
    height: 100%;
    background: black;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .integrarion-container .codes img {
    max-height: 100%;
    max-width: 100%;
  }
  .integration:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 50rem;
    width: 100%;
    background: #1e1d20;
    -webkit-transform: skew(0, 8.1deg);
    transform: skew(0, 8.1deg) translateY(30rem);
  }
  .marquee-two {
    transform: translateY(-8rem);
    width: 130vw;
  }
  .description h3 {
    font-size: 2rem;
  }
  .description h2 {
    font-weight: 400;
  }
  @media (min-width: 768px) {
    .integration {
      margin-top: 40rem;
      padding-top: 20rem;
    }
    .integration:before {
      height: 50rem;
    }
    .number {
      font-size: 400.73px;
    }
    .number-container {
      position: absolute;
      transform: rotate(8.19deg);
      top: 50rem;
      z-index: -1;
      display: block;
      border-radius: 27px;
      /* margin-left: -4rem; */
      /* transform: translateX(-3rem); */

      /* overflow-x: hidden; */
    }
    .integrarion-container {
      grid-template-columns: 40% 1fr;
      padding: 9rem 3rem 0 5rem;
      max-width: 70.438rem;
    }
    .integrarion-container .description {
      font-size: 61.45px;
      text-align: left;
    }
    .integrarion-container .description .text {
      font-size: 21.2727px;
      font-weight: 500;
    }
    .marquee-container {
      transform: rotate(8.19deg) translateX(-3rem);
      /* position: absolute; */
      top: 0;
      z-index: 5;
      height: 10rem;
    }
    .marquee {
      padding-top: 2rem;
      width: 110vw;
    }
    .marquee-two {
      transform: translateY(-2rem);
      position: relative;
      z-index: 2;
    }
    .number-two {
      transform: translateX(-3rem);
    }
    .description h1 {
      font-size: 3.841rem;
    }
  }
</style>
