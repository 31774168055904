<template>
  <div>
    <div class="close">
      <Header />
    </div>
    <main class="close">
      <Interest />
      <div class="twin-background">
        <Testimonials />
        <Merchants />
      </div>
      <Smooth />
      <People />
      <!-- <App /> -->
      <Faq />
    </main>
    <!-- <Footer /> -->
  </div>
</template>
<script>
  import Header from "./Header";
  import Merchants from "./Merchants";
  import Smooth from "./smooth";
  import Footer from "./Footer";
  import Testimonials from "./Testimonials";
  import Faq from "./Faq";
  import People from "./People";
  import App from "./App";
  import Interest from "./Interest";
  export default {
    components: {
      Header,
      Merchants,
      Smooth,
      Footer,
      Testimonials,
      Faq,
      People,
      App,
      Interest,
    },
  };
</script>
<style scoped>
  .close {
    overflow: hidden;
  }
  .twin-background {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      radial-gradient(
          78.5% 146.7% at 24.68% 147.87%,
          rgba(98, 223, 223, 0.44) 0%,
          rgba(98, 223, 223, 0.02) 47.92%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(
          87.6% 165.19% at 56.36% 140.03%,
          rgba(198, 0, 59, 0.69) 0%,
          rgba(198, 1, 60, 0) 51.56%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(
          115.29% 217.4% at 4.31% 98.5%,
          #6956c8 0%,
          rgba(25, 12, 91, 0) 74.05%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      #000000;
  }
</style>
