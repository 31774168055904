<template>
  <HomePage />
</template>

<script>
  // @ is an alias to /src
  import HomePage from "@/components/Home";

  export default {
    name: "Home",
    components: {
      HomePage,
    },
  };
</script>
