<template>
  <div class="general">
    <div class="scroll-container" ref="scroll">
      <div
        class="wrapper first"
        data-src="https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1649431769/website/SDK_-_ZILLA_EXPRESS_1_1_dwput8.png"
      >
        <div>
          <h5 class="point">1.</h5>
          <p class="description">
            Your customers see what they like in your store
          </p>
        </div>
      </div>
      <div
        class="wrapper second"
        data-src="https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1649424970/website/SDK_-_ZILLA_EXPRESS_oisy2o.png"
      >
        <div></div>
        <div>
          <h5 class="point">2.</h5>
          <p class="description">They choose zilla as their payment method</p>
        </div>
      </div>
      <div
        class="wrapper third"
        data-src="https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1649425192/website/SDK_-_ZILLA_EXPRESS-1_deoquh.png"
      >
        <div>
          <h5 class="point">3.</h5>
          <p class="description">
            Your customer completes checkout, and you get your full payment
            immediately.
          </p>
        </div>
      </div>
    </div>
    <div class="image-hand">
      <transition name="slideY" mode="out-in">
        <img :src="imageSrc" alt="image step" class="screens" :key="imageSrc" />
      </transition>
      <img
        class="hand"
        src="https://res.cloudinary.com/zillaafrica/image/upload/v1649414993/website/Offer_credit_hand_model_frame_1_ddetne.png"
        alt=""
      />
    </div>
  </div>
</template>
<script>
  import Observer from "vue-intersection-observer";
  export default {
    components: {
      Observer,
    },
    mounted() {
      this.observeImage();
    },
    data: () => ({
      observer: null,
      imageSrc:
        "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1649431769/website/SDK_-_ZILLA_EXPRESS_1_1_dwput8.png",
      imagePosition: "absolute",
      options: {
        root: null,
        rootMargin: "30px",
        threshold: [0.8],
      },
      items: [
        {
          name: "first",
          number: "1.",
          description: "Your customers see what they like in your store",
          direction: "normal",
        },
        {
          name: "second",
          number: "2.",
          description: "Your customers see what they like in your store",
          direction: "reverse",
        },
      ],
    }),
    methods: {
      onChange(entry, unobserve) {
        if (entry.isIntersecting) {
          this.imagePosition = "sticky";
        }
      },
      observeImage() {
        const images = this.$refs.scroll.children;
        this.observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              this.imageSrc = entry.target.dataset.src;
              console.log(this.imageSrc);
            }
          });
        }, this.options);
        Array.prototype.forEach.call(images, (image) => {
          this.observer.observe(image);
        });
      },
    },
  };
</script>
<style scoped>
  .general {
    position: relative;
    display: none;
  }
  .scroll-container {
    max-width: 72rem;
    margin: auto;
    position: relative;
    padding-top: 3rem;
  }
  .scroll-container .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 25rem;
  }

  .point {
    font-size: 454.765px;
    line-height: 120%;
    background-image: linear-gradient(
      217.04deg,
      #c6003b -191.09%,
      #6a30c3 77.43%,
      #63dfdf 156.06%
    );
    background-clip: text;
    font-weight: bold;
    color: transparent;
    /* display: inline-block; */
  }
  .wrapper {
    text-align: right;
  }
  .description {
    color: #1e1d20;
    font-size: 35.9232px;
    line-height: 120%;
    /* text-align: right; */
    margin-top: -7rem;
    font-weight: bold;
  }
  .second {
    text-align: left;
  }
  .image-hand {
    top: 0;
    position: sticky;
    bottom: 0;
    width: 30.25rem;
    margin: auto;
    text-align: center;
    left: -50%;
    right: -50%;
    margin-top: 5rem;
    /* height: 53.053rem; */
  }
  .image-hand .hand {
    width: 100%;
    max-height: 100%;
    position: relative;
    z-index: 2;
  }
  .screens {
    position: absolute;
    z-index: 1;
    width: 13rem;
    top: 0.8em;
    left: 4.2rem;
  }
  .slideY-enter-active,
  .slideY-leave-active {
    transition: all 0.3s ease-out;
    transform: translateX(0);
  }

  .slideY-enter {
    opacity: 0;
    transform: translateY(10px);
  }

  .slideY-leave-to {
    opacity: 0;
    transform: translateY(-10px);
  }
  @media (min-width: 768px) {
    .general {
      display: block;
    }
  }
</style>
