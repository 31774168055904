<template>
  <section class="merchant-container">
    <h2 class="merchant-heading">
      <span>#usezilla</span> anywhere- with over 4000 merchants and counting
    </h2>
    <!-- <div class="marquee-container"> -->
    <marquee-text
      :duration="100"
      :repeat="3"
      reverse
      class="marquee marquee-two"
    >
      <div class="first-level__merchants">
        <div v-for="(category, index) in categoriesData" :key="index">
          <img :src="category.iconLink" alt="" loading="lazy" />
          <p>{{ category.name }}</p>
        </div>
      </div>
    </marquee-text>

    <marquee-text :duration="120" :repeat="3">
      <div class="bigger-merchant">
        <img
          v-for="i in firstMerchantArray"
          :key="i"
          :src="i"
          alt="merchant"
          loading="lazy"
        />
      </div>
    </marquee-text>
    <marquee-text
      :duration="150"
      :repeat="3"
      reverse
      class="marquee marquee-two"
    >
      <div class="first-level__merchants">
        <div v-for="(category, index) in reversedCategories" :key="index">
          <img :src="category.iconLink" alt="" loading="lazy" />
          <p>{{ category.name }}</p>
        </div>
      </div>
    </marquee-text>
    <marquee-text :duration="140" :repeat="3">
      <div class="bigger-merchant second-bigger__merchant">
        <img
          v-for="i in secondImage"
          :key="i"
          :src="i"
          alt="merchant"
          loading="lazy"
        />
      </div>
    </marquee-text>
    <div class="overflow-hidden hero-svg items-center" width="4000px">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        v-for="i in 5"
        :key="i"
        width="1299"
        height="83"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1214.93 0h-23.02c-6.01 14.8664-18.9 25.1444-33.84 25.1444-14.93 0-27.83-10.278-33.84-25.1444h-23.02c-6.01 14.8664-18.91 25.1444-33.84 25.1444-14.94 0-27.83-10.278-33.84-25.1444h-23.03c-6.02 14.8664-18.909 25.1444-33.846 25.1444-14.937 0-27.831-10.278-33.842-25.1444h-23.03c-6.01 14.8664-18.904 25.1444-33.841 25.1444-14.937 0-27.831-10.278-33.842-25.1444h-23.023c-6.01 14.8664-18.904 25.1444-33.841 25.1444-14.937 0-27.831-10.278-33.842-25.1444H725v83h574V0h-16.39c-6.01 14.8664-18.9 25.1444-33.84 25.1444-14.94 0-27.83-10.278-33.84-25.1444ZM105.929 0H82.9142c-6.0103 14.8664-18.9045 25.1444-33.8414 25.1444-14.9369 0-27.8311-10.278-33.8414-25.1444H-7.79089C-13.8011 14.8664-26.6954 25.1444-41.6323 25.1444c-14.9369 0-27.8311-10.278-33.8414-25.1444h-23.0311c-6.0102 14.8664-18.9042 25.1444-33.8412 25.1444-14.937 0-27.831-10.278-33.842-25.1444h-23.03c-6.01 14.8664-18.904 25.1444-33.841 25.1444-14.937 0-27.831-10.278-33.841-25.1444h-23.024c-6.01 14.8664-18.904 25.1444-33.841 25.1444-14.937 0-27.831-10.278-33.841-25.1444H-374v83h574V0h-26.388c-6.011 14.8664-18.905 25.1444-33.842 25.1444-14.937 0-27.831-10.278-33.841-25.1444Z"
          fill="#fff"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M656.929 0h-23.015c-6.01 14.8664-18.904 25.1444-33.841 25.1444-14.937 0-27.831-10.278-33.842-25.1444h-23.022c-6.01 14.8664-18.904 25.1444-33.841 25.1444-14.937 0-27.831-10.278-33.842-25.1444h-23.031c-6.01 14.8664-18.904 25.1444-33.841 25.1444-14.937 0-27.831-10.278-33.842-25.1444h-23.03c-6.01 14.8664-18.904 25.1444-33.841 25.1444-14.937 0-27.831-10.278-33.841-25.1444h-23.024c-6.01 14.8664-18.904 25.1444-33.841 25.1444-14.937 0-27.831-10.278-33.841-25.1444H177v83h574V0h-26.388c-6.011 14.8664-18.905 25.1444-33.842 25.1444-14.937 0-27.831-10.278-33.841-25.1444Z"
          fill="#fff"
        />
      </svg>
    </div>
  </section>
</template>
<script>
  import categoriesData from "@/database/categories.json";
  import { MerchantImage, SecondImage } from "@/database/merchant.js";
  import MarqueeText from "@/UI//Marquee.vue";
  export default {
    components: {
      MarqueeText,
    },
    data: () => ({
      categoriesData: categoriesData,
      merchantImage: MerchantImage,
      secondImage: SecondImage,
    }),
    computed: {
      reversedCategories() {
        return this.categoriesData.reverse();
      },
      firstMerchantArray() {
        return this.merchantImage.splice(0, 10);
      },
      secondMerchantArray() {
        return this.merchantImage.slice(11, this.merchantImage.length);
      },
    },
  };
</script>
<style scoped>
  .merchant-container {
    position: relative;
    padding: 6rem 0;
    min-height: 55.313rem;
  }
  .hero-svg {
    display: flex;
    position: absolute;
    bottom: -1rem;
    left: 0;
    z-index: 2;
  }

  .container-marquee {
    display: flex;
    align-items: center;
    /* background-color: green; */
    /* margin-top: 5rem; */
    position: relative;
    z-index: 500;
  }

  .container-body {
    height: 3.8rem !important;
    margin-top: 1rem;
  }
  .bigger-merchant__marquee {
    height: 10.563rem !important;
    margin-top: 1rem;
    /* margin-right: 1rem; */
  }
  .first-level__merchants {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 1rem;
  }
  .first-level__merchants p {
    font-size: 0.75rem;
  }
  .first-level__merchants div {
    background-color: white;
    border-radius: 9px;
    padding: 0.3rem;
    /* height: 2.8rem; */
    min-width: 10.25rem;
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
    justify-content: center;
  }

  .first-level__merchants img {
    width: 2rem;
    min-height: 2rem;
    height: auto;
  }
  .bigger-merchant {
    display: grid;
    grid-template-columns: repeat(10, 14rem);
    grid-gap: 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
  .second-bigger__merchant {
    grid-template-columns: repeat(9, 14rem);
  }
  .bigger-merchant img {
    width: 100%;
    /* max-height: 14.563rem; */
  }
  .merchant-heading {
    color: white;
    font-size: 1.563rem;
    text-align: center;
    width: 80%;
    margin: 0rem auto 3.625rem auto;
  }
  .merchant-heading span {
    color: #918e9c;
  }

  @media (min-width: 768px) {
    .merchant-container {
      min-height: 81.313rem;
      padding-top: 20rem;
    }
    .container-body {
      height: 4.75rem !important;
      margin-top: 1rem;
    }
    .merchant-heading {
      color: white;
      font-size: 3.063rem;
      max-width: 49.5rem;
    }
    .first-level__merchants {
      /* height: 4.75rem; */
      min-width: 18.25rem;
    }
    .first-level__merchants div {
      margin-right: 1rem;
    }
    .bigger-merchant {
      display: grid;
      grid-template-columns: repeat(10, 25.563rem);
      grid-gap: 1rem;
    }
    .second-bigger__merchant {
      grid-template-columns: repeat(9, 25.563rem);
    }
    .bigger-merchant__marquee {
      height: 17.875rem !important;
    }
    .bigger-merchant img {
      min-height: 17.875rem;
      width: 100%;
    }

    .first-level__merchants div {
      background-color: white;
      border-radius: 9px;
      padding: 0.5rem;
      /* height: 2.8rem; */
      min-width: 13.25rem;
      display: flex;
      align-items: center;
      margin-right: 1rem;
    }
  }
</style>
