var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container choice-container mx-auto"},[_c('div',[_c('h3',{staticClass:"text-darkGrey choice-heading ch"},[_vm._v("WHY CHOOSE ZILLA")]),_vm._l((_vm.cards),function(card,index){return _c('div',{key:index,staticClass:"body"},[_c('div',{staticClass:"choice-card relative",style:({
          background: card.background,
          'flex-direction':
            card.direction === 'reverse' ? 'row-reverse' : 'row',
        })},[_c('p',{staticClass:"background-text",style:({
            bottom: card.name.text.includes('Business') ? '-54rem' : '-20rem',
            color: card.name.color,
          }),domProps:{"innerHTML":_vm._s(card.name.text)}}),_c('figure',{staticClass:"flex items-center relative z-2"},[_c('img',{staticClass:"max-h-full max-w-full mx-auto choice-image",attrs:{"loading":"eager","src":card.image,"alt":"instant settlement"}})]),_c('div',{staticClass:"second-section flex items-center"},[_c('div',{staticClass:"choice-description instant",class:[card.direction === 'reverse' && 'lg:text-right']},[_c('div',{staticClass:"card-heading",style:({ color: card.name.color }),domProps:{"innerHTML":_vm._s(card.name.text)}}),_c('p',{staticClass:"mt-4 description",style:({ color: card.description.color })},[_vm._v(" "+_vm._s(card.description.text)+" ")])])])])])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }