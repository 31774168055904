export const MerchantImage = [
  "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1649345146/website/Frame_13474_f8dwf3.png",
  "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1649345084/website/Frame_13472_1_q6awwm.png",
  "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1649345047/website/Frame_13471_1_i1e6dc.png",
  "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1649344980/website/Frame_13470_1_c4ozot.png",
  "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1649344667/website/Frame_13469_1_mb0itf.png",
  "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1649344332/website/Frame_13467_1_zcsxoo.png",
  "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1649344241/website/Frame_13466_6_pj5b0w.png",
  "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1649344098/website/Frame_13466_5_rfx6d6.png",
  "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1649343998/website/Frame_13466_4_kfa89y.png",
  "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1649343919/website/Frame_13473_jxbmrn.png",
];

export const SecondImage = [
  "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1649343856/website/Frame_13472_qurgfr.png",
  "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1649343758/website/Frame_13471_elqpaj.png",
  "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1649343714/website/Frame_13470_so2hv1.png",
  "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1649343657/website/Frame_13469_mlcb5a.png",
  "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1649343429/website/Frame_13468_ss1qqa.png",
  "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1649343265/website/Frame_13467_tew4v6.png",
  "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1649343203/website/Frame_13466_3_x0h8vv.png",
  "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1649342915/website/Frame_13466_2_iydl2i.png",
  "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1649342870/website/Frame_13466_1_hmn12r.png",
];
