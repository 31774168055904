<template>
  <section class="testimonials-container">
    <!-- <div> -->
    <div class="overflow-hidden hero-svg items-center" width="4000px">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="574"
        height="83"
        fill="none"
        v-for="i in 20"
        :key="i"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M94.0703 82.9998h23.0157c6.01-14.8664 18.904-25.1444 33.841-25.1444 14.937 0 27.831 10.278 33.842 25.1444h23.022c6.01-14.8664 18.904-25.1444 33.841-25.1444 14.937 0 27.831 10.278 33.842 25.1445h23.031c6.01-14.8665 18.904-25.1445 33.841-25.1445 14.937 0 27.831 10.278 33.842 25.1445h23.03c6.01-14.8665 18.904-25.1445 33.841-25.1445 14.937 0 27.831 10.278 33.841 25.1445h23.023c6.01-14.8665 18.904-25.1445 33.841-25.1445 14.937 0 27.831 10.278 33.842 25.1445H574V-.00012L0-.00017V82.9998h26.3876c6.0102-14.8664 18.9045-25.1444 33.8413-25.1444 14.9369 0 27.8312 10.278 33.8414 25.1444Z"
          fill="#fff"
        />
      </svg>
    </div>
    <div>
      <div class="content-container container">
        <h3 class="second-heading">
          <span class="text-pad">From hair to shoes, handbags to travel,</span>
          shoppers that #usezilla know
          <span class="text-pad">that there is no better way to pay.</span>
        </h3>
        <a :href="customerUrl" target="_blank" rel="noopener noreferrer">
          <button class="testimonial-button">GET IN</button>
        </a>
      </div>
      <!-- <div class="testimonial-rounder"> -->
      <Flickity ref="flickity" :options="flickityOptions" class="flickity">
        <!-- <div class="testimonial-content"> -->
        <div
          class="testimonial-item"
          v-for="(i, index) in testomonialText"
          :key="index"
          :style="{ 'background-image': `url(${i.background})` }"
        >
          <p>{{ i.text }}</p>
        </div>

        <!-- </div> -->
        <!-- </div> -->
      </Flickity>
    </div>

    <!-- </div> -->
  </section>
</template>
<script>
  import { backgrounds, testomonialText } from "./testimonialsDb";
  import Flickity from "vue-flickity";
  export default {
    components: {
      Flickity,
    },
    data: () => ({
      customerUrl: process.env.VUE_APP_CUSTOMER_URL,
      backgrounds: backgrounds,
      testomonialText: testomonialText,
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: false,
        cellAlign: "left",
        wrapAround: true,
      },
    }),
  };
</script>
<style scoped>
  @import url("https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap");

  .testimonials-container {
    min-height: 46rem;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    display: grid;
    place-items: center;
  }
  .hero-svg {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    overflow: hidden;
  }
  .content-container {
    text-align: left;
  }

  .first-heading {
    font-size: 2.375rem;
    text-align: left;
  }
  .second-heading {
    /* max-width: 62rem; */
    margin: auto;
    color: white;
    font-size: 2.375rem;
    line-height: 100%;
    text-align: left;
  }

  .testimonial-button {
    color: white;
    border-radius: 7.3629px;
    background: rgba(255, 255, 255, 0.1);
    width: 70%;

    padding: 1rem;
    outline: none;
    border: none;
    margin: 2.625rem auto 0 auto;
    text-align: center !important;
    cursor: pointer;
    letter-spacing: 0.11em;
  }
  .image {
    position: absolute;
    bottom: -5rem;
    max-width: 100%;
    z-index: 6;
    max-height: 24.875rem;
  }
  .testimonial-rounder {
    overflow-x: scroll;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateY(3rem);
    padding: 1rem 0;
  }
  .testimonial-content {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    min-width: 1200px;
  }
  .testimonial-item {
    margin-right: 0.8rem;
    background-size: cover;
    padding: 0.5rem;
    display: grid;
    place-items: center;
    transform: rotate(0) translateX(0);
    height: 6.563rem;
    width: 11.188rem;
    cursor: pointer;
    z-index: 5;
  }
  .testimonial-item:hover {
    transform: rotate(4deg) !;
  }

  .testimonial-item:nth-child(even):hover {
    transform: rotate(-4deg);
  }
  .testimonial-item p {
    text-align: center;
    font-size: 0.7rem;
    font-family: "Gloria Hallelujah", cursive;
    line-height: 25.89px;
    line-height: 150%;
  }
  .flickity {
    width: 100vw;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(0rem);
  }

  .flickity-button {
    display: none;
  }
  @media (min-width: 768px) {
    .testimonials-container {
      min-height: 69.563rem;
      margin-top: 13rem;
    }
    .first-heading {
      font-size: 4.75rem;
      text-align: center;
    }
    .second-heading {
      max-width: 62rem;
      margin: auto;
      color: white;
      font-size: 4.75rem;
      /* text-align: ; */
    }
    .testimonial-button {
      width: 27.784rem;
      height: 5.063rem;
    }
    .image {
      bottom: -10rem;
    }
    .testimonial-item {
      transform: rotate(0) translateX(0);
      height: 16.313rem;
      padding: 2rem;
      width: 27.625rem;
      cursor: pointer;
    }

    .testimonial-item p {
      font-size: 1rem;
    }
    .testimonial-rounder {
      overflow-x: scroll;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 5;

      transform: translateY(10rem);
    }

    .flickity {
      transform: translateY(10rem);
      z-index: 4;
    }
    .testimonial-content {
      min-width: 170rem;

      /* transform: translateY(10rem); */
    }
  }

  ::-webkit-scrollbar {
    width: 1px;
  }

  /* Track */
  .testimonial-rounder::-webkit-scrollbar-track {
    background: #f1f1f1;
    height: 1px;
    display: none;
  }

  /* Handle */
  .testimonial-rounder::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
    height: 1px;
    display: none;
  }

  /* Handle on hover */
  .testimonial-rounder::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
</style>
<style>
  .flickity-button {
    display: none;
  }
  @media (min-width: 768px) {
    .flickity-button {
      display: block;
    }
  }
</style>
