<template>
  <div>
    <Header />
    <Choose />
    <Credit />
    <ScrollDescription />
    <Reasons />
    <Calculator />
    <Timer />
    <Integration />
    <Faq />
  </div>
</template>
<script>
  import Header from "@/components/Merchant/Home/Header";
  import Choose from "@/components/Merchant/Home/Choose";
  import Credit from "@/components/Merchant/Home/Credit";
  import ScrollDescription from "@/components/Merchant/Home/ScrollDescription";
  import Reasons from "@/components/Merchant/Home/Reasons";
  import Calculator from "@/components/Merchant/Home/Calculator";
  import Timer from "@/components/Merchant/Home/Timer";
  import Integration from "@/components/Merchant/Home/Integration";
  import Faq from "@/components/Merchant/Home/Faq";
  export default {
    components: {
      Header,
      Choose,
      Credit,
      ScrollDescription,
      Reasons,
      Calculator,
      Timer,
      Integration,
      Faq,
    },
  };
</script>
