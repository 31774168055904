<template>
  <div class="footer">
    <div class="container mx-auto footer-container">
      <div class="address-details">
        <p class="footer-company">Zilla Finance, Inc.</p>
        <p class="heading-list">Copyright © 2022 Zilla Finance, Inc.</p>
        <p />
        <p class="heading-list">USA: 256 Chapman Rd, Newark, Delaware, USA</p>
        <p class="heading-list">
          Nigeria: Workcity, Polystar building, 2nd round about, Lekki Phase1, Lagos, Nigeria
        </p>
        <p class="heading-list">
          Zilla Technologies LTD (RC:1795543) is a company of Zilla Finance,
          Inc.
        </p>
        <p class="heading-list">All rights reserved.</p>
      </div>
      <div class="footer-second__section">
        <div>
          <p class="footer-heading">Zilla</p>
          <a :href="customerUrl" target="_blank" rel="noopener noreferrer">
            <p class="heading-list">For Customer</p>
          </a>
          <a :href="merchantUrl" target="_blank" rel="noopener noreferrer">
            <p class="heading-list">For Merchant</p>
          </a>
          <a
            href="https://blog.usezilla.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p class="heading-list">Blog</p>
          </a>
          <a
            href="https://zilla-checkout.netlify.app/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p class="heading-list">#ExperienceZilla</p>
          </a>
        </div>
        <div>
          <p class="footer-heading">Resources</p>
          <a
            href="https://usezilla.notion.site/Zilla-Merchants-db66b8917e2e4f83a2b72e1d70c82d98"
            target="_blank"
          >
            <p class="heading-list">Where to shop</p>
          </a>
          <a href="http://faq.usezilla.com/" target="_blank">
            <p class="heading-list">FAQs</p>
          </a>
          <a
            href="https://usezilla.notion.site/Legals-02234eab75664f149447f8a3c60c4c14"
            target="_blank"
          >
            <p class="heading-list">Legals</p>
          </a>
          <a
            href="https://usezilla.notion.site/Developers-Documentation-3472cedcf86d47378dec187b32445eb1"
            target="_blank"
          >
            <p class="heading-list">Developers' doc</p>
          </a>
        </div>
        <div>
          <p class="footer-heading">Talk to us</p>
          <a href="mailto:boost@zilla.africa">
            <p class="heading-list">boost[at]zilla[dot]africa</p>
          </a>
          <!-- <p class="heading-list">+2348156271291</p> -->
        </div>
      </div>
    </div>
    <div class="last-line__footer container">
      <div class="inner-container">
        <a
          href="https://usezilla.notion.site/Data-Privacy-Protection-Cookie-Policy-fd834fd91648458fb5bbf68acce27242"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p class="privacy">Terms and Privacy</p>
        </a>

        <div class="social-media-container">
          <a
            href="https://instagram.com/usezilla"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
            >
              <path
                d="M8 1.44062c2.1375 0 2.3906.00938 3.2313.04688.7812.03437 1.2031.16563 1.4843.275.3719.14375.6407.31875.9188.59688.2812.28124.4531.54687.5969.91875.1093.28125.2406.70625.275 1.48437.0375.84375.0468 1.09688.0468 3.23125 0 2.13755-.0093 2.39065-.0468 3.23125-.0344.7813-.1657 1.2031-.275 1.4844-.1438.3719-.3188.6406-.5969.9187-.2813.2813-.5469.4532-.9188.5969-.2812.1094-.7062.2406-1.4843.275-.8438.0375-1.0969.0469-3.2313.0469-2.1375 0-2.39062-.0094-3.23125-.0469-.78125-.0344-1.20312-.1656-1.48437-.275-.37188-.1437-.64063-.3187-.91875-.5969-.28125-.2812-.45313-.5468-.59688-.9187-.10937-.2813-.24062-.7063-.275-1.4844-.0375-.8437-.04687-1.0969-.04687-3.23125 0-2.1375.00937-2.39062.04687-3.23125.03438-.78125.16563-1.20312.275-1.48437.14375-.37188.31875-.64063.59688-.91875.28125-.28126.54687-.45313.91875-.59688.28125-.10937.70625-.24063 1.48437-.275C5.60938 1.45 5.8625 1.44062 8 1.44062ZM8 0C5.82813 0 5.55625.00937 4.70313.04688c-.85.0375-1.43438.175-1.94063.37187-.52812.20625-.975.47812-1.41875.925-.44688.44375-.71875.89063-.925 1.41563C.22187 3.26875.08437 3.85.04687 4.7.00938 5.55625 0 5.82812 0 8c0 2.1719.00937 2.4438.04688 3.2969.0375.85.175 1.4344.37187 1.9406.20625.5281.47812.975.925 1.4187.44375.4438.89063.7188 1.41563.9219.50937.1969 1.09062.3344 1.94062.3719.85313.0375 1.125.0469 3.29688.0469 2.17192 0 2.44372-.0094 3.29692-.0469.85-.0375 1.4343-.175 1.9406-.3719.525-.2031.9719-.4781 1.4156-.9219.4438-.4437.7188-.8906.9219-1.4156.1969-.5093.3344-1.0906.3719-1.9406.0375-.8531.0468-1.125.0468-3.29687 0-2.17188-.0093-2.44375-.0468-3.29688-.0375-.85-.175-1.43437-.3719-1.94063-.1969-.53124-.4688-.97812-.9156-1.42187C14.2125.9 13.7656.625 13.2406.42187 12.7313.225 12.15.0875 11.3.05 10.4438.00937 10.1719 0 8 0Z"
                fill="#C2C0C7"
              />
              <path
                d="M8 3.89062C5.73125 3.89062 3.89062 5.73125 3.89062 8c0 2.2688 1.84063 4.1094 4.10938 4.1094 2.2688 0 4.1094-1.8406 4.1094-4.1094 0-2.26875-1.8406-4.10938-4.1094-4.10938Zm0 6.77498c-1.47187 0-2.66563-1.19372-2.66563-2.6656 0-1.47187 1.19376-2.66563 2.66563-2.66563 1.47188 0 2.6656 1.19376 2.6656 2.66563 0 1.47188-1.19372 2.6656-2.6656 2.6656ZM13.2312 3.72793c0 .53125-.4312.95938-.9593.95938-.5313 0-.9594-.43125-.9594-.95938 0-.53125.4313-.95938.9594-.95938s.9593.43126.9593.95938Z"
                fill="#C2C0C7"
              />
            </svg>
          </a>

          <a
            href="https://facebook.com/usezilla"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
            >
              <path
                d="M16 8c0-4.41828-3.5817-8-8-8-4.41828 0-8 3.58172-8 8 0 3.993 2.92547 7.3027 6.75 7.9028v-5.5903H4.71875V8H6.75V6.2375c0-2.005 1.19438-3.1125 3.02172-3.1125.87498 0 1.79078.15625 1.79078.15625V5.25h-1.0087C9.56 5.25 9.25 5.86672 9.25 6.5V8h2.2188l-.3547 2.3125H9.25v5.5903C13.0745 15.3027 16 11.993 16 8Z"
                fill="#C2C0C7"
              />
            </svg>
          </a>
          <a
            href="https://twitter.com/usezilla"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
            >
              <g clip-path="url(#a)">
                <path
                  d="M5.03344 14.5c6.03626 0 9.33876-5.0022 9.33876-9.33876a9.5654 9.5654 0 0 0-.0094-.425A6.66667 6.66667 0 0 0 16 3.03687a6.47224 6.47224 0 0 1-1.8841.51562 3.30012 3.30012 0 0 0 1.4435-1.81531 6.59883 6.59883 0 0 1-2.0838.79656 3.28658 3.28658 0 0 0-1.8625-.99104 3.28692 3.28692 0 0 0-3.52387 1.88484 3.28689 3.28689 0 0 0-.20954 2.09933 9.32333 9.32333 0 0 1-3.74637-.9948 9.32333 9.32333 0 0 1-3.01801-2.43239 3.28626 3.28626 0 0 0 1.01563 4.38031 3.28653 3.28653 0 0 1-1.48719-.40938v.04063A3.28126 3.28126 0 0 0 3.2775 9.32999a3.26279 3.26279 0 0 1-1.48094.05625 3.28928 3.28928 0 0 0 1.16729 1.63136 3.28974 3.28974 0 0 0 1.89771.6499 6.58334 6.58334 0 0 1-4.07719 1.4062A6.57033 6.57033 0 0 1 0 13.0256 9.31237 9.31237 0 0 0 5.03344 14.5Z"
                  fill="#C2C0C7"
                />
              </g>
              <defs>
                <clipPath id="a">
                  <path fill="#fff" d="M0 0h16v16H0z" />
                </clipPath>
              </defs>
            </svg>
          </a>
          <a
            href="https://www.youtube.com/channel/UC2XgOpnSQIkXgmP1Aw1jfQg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
            >
              <path
                d="M15.8406 4.8002s-.1562-1.10313-.6375-1.5875c-.6093-.6375-1.2906-.64063-1.6031-.67813-2.2375-.1625-5.59687-.1625-5.59687-.1625h-.00626s-3.35937 0-5.59687.1625c-.3125.0375-.99375.04063-1.60312.67813C.31561 3.69707.1625 4.8002.1625 4.8002S0 6.09707 0 7.39082v1.2125c0 1.29375.15937 2.59058.15937 2.59058s.15625 1.1032.63438 1.5875c.60938.6375 1.40938.6157 1.76563.6844C3.84063 13.5877 8 13.6252 8 13.6252s3.3625-.0063 5.6-.1656c.3125-.0375.9938-.0407 1.6031-.6782.4813-.4843.6375-1.5875.6375-1.5875S16 9.90019 16 8.60332v-1.2125c0-1.29375-.1594-2.59062-.1594-2.59062Zm-9.49372 5.275V5.57832l4.32182 2.25625-4.32182 2.24063Z"
                fill="#C2C0C7"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data: () => ({
      customerUrl: process.env.VUE_APP_CUSTOMER_URL,
      merchantUrl: process.env.VUE_APP_MERCHANT_URL,
    }),
  };
</script>
<style scoped>
  .footer {
    position: relative;
    /* z-index: 5; */
    background: #1e1d20;
    color: white;
  }
  .footer-container {
    padding: 5rem 0;
    display: grid;
    grid-template-columns: 50% auto;
    /* width: 90%; */
  }
  .footer-company {
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
  }
  .footer-second__section {
    display: grid;
    grid-template-columns: repeat(3, 33.33%);
    /* font-size: 20px; */
  }
  .footer-heading {
    font-size: 14px;
    font-weight: 600;
  }
  .heading-list {
    margin-top: 1.25rem;
    cursor: pointer;
    font-size: 12px;
    color: white;
    line-height: 150%;
  }
  .last-line__footer {
    display: flex;
    justify-content: flex-end;
  }
  .social-media-container {
    display: flex;
    align-items: center;
  }
  .inner-container {
    display: flex;
    align-items: center;
    padding: 3rem 0;
  }

  .inner-container p {
    margin-right: 4rem;
    font-size: 14px;
  }
  .social-media-container svg {
    margin-right: 1.5rem;
    cursor: pointer;
  }
  .privacy {
    color: white;
  }
  @media (min-width: 769px) {
    .address-details {
      max-width: 25rem;
    }
  }
  @media (max-width: 768px) {
    .footer-container {
      grid-template-columns: 100%;
      width: 90%;
    }
    .footer-second__section {
      grid-template-columns: 100%;
    }
    .footer-heading {
      margin-top: 1.5rem;
      font-size: 1rem;
    }

    .heading-list {
      font-size: 14px;
    }
    .last-line__footer {
      display: flex;
      justify-content: flex-start;
      max-width: 90%;
      margin: auto;
    }
  }

  a {
    text-decoration: none;
    color: initial;
  }
</style>
