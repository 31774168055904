<template>
  <div class="countdown-container container mx-auto">
    <div class="laptop-container">
      <img
        class="laptop-image"
        src="https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1649403099/website/1458_1_taglne.png"
        alt="laptop"
        loading="lazy"
      />
    </div>
    <observer @on-change="onChange">
      <div class="timer-container rotate h-full relative flex align-start">
        <div class="timer-numbers flex items-center">
          <h1 class="countdown">{{ minutes }}</h1>
          <h1 class="countdown">:</h1>
          <h1 class="countdown">{{ seconds }}</h1>
        </div>
      </div>
    </observer>
    <div class="countdown__description-section">
      <h1 class="heading">Create an account in 3 mins</h1>
      <p class="lg:mt-6 description">
        Starting your journey with Zilla is quick and easy. Sign up in 3
        minutes, from the comfort of your home.
      </p>
      <button
        class="counter-button flex items-center"
        @click="$router.push('/login')"
      >
        <p>JOIN ZILLA</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="21"
          fill="none"
        >
          <path
            d="M3.20832.30689.88769 2.6275l7.53792 7.55439-7.53792 7.5544 2.32063 2.3206 9.87498-9.875L3.20832.30688Z"
            fill="#6956C8"
          />
        </svg>
      </button>
    </div>
    <!-- <h1 class="countdown font-bold">02:56</h1> -->
  </div>
</template>
<script>
  import Observer from "vue-intersection-observer";
  export default {
    components: {
      Observer,
    },
    data: () => ({
      minutes: "00",
      seconds: "00",
    }),
    mounted() {
      // this.startTimer();
    },
    methods: {
      startTimer() {
        let timer = 60 * 3,
          minutes,
          seconds;
        setInterval(() => {
          minutes = parseInt(timer / 60, 10);
          seconds = parseInt(timer % 60, 10);
          this.minutes = minutes < 10 ? "0" + minutes : minutes;
          this.seconds = seconds < 10 ? "0" + seconds : seconds;
          if (--timer < 0) {
            this.minutes = "00";
            this.seconds = "00";
          }
        }, 1000);
      },
      onChange(entry, unobserve) {
        if (entry.isIntersecting) {
          this.startTimer();
          unobserve();
        }
      },
    },
  };
</script>
<style scoped>
  .countdown-container {
    display: grid;
    grid-template-columns: 100%;
    position: relative;
    /* height: 150.374rem; */
  }
  .countdown {
    font-size: 15.968rem;
    background: linear-gradient(
      217.04deg,
      #c6003b -191.09%,
      #6a30c3 77.43%,
      #63dfdf 156.06%
    );
    font-weight: bold;
    background-clip: text;
    color: transparent;

    /* transform: rotate(90deg); */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* padding-right: 500px; */
  }
  .rotate {
    transform: rotate(270deg);
    transform-origin: 90% 90%;
    /* height: 953.24px; */
  }
  .heading {
    font-size: 2rem;
    font-weight: bold;
    line-height: 73.75px;
    margin-top: 307px;
  }
  .description {
    color: #918e9c;
    font-size: 1.125rem;

    line-height: 150%;
  }
  .counter-button {
    border: 1px solid #6956c8;
    border-radius: 10px;
    color: #6956c8;
    padding: 16px 24px;
    margin-top: 40px;
    cursor: pointer;
    background: none;
    outline: none;
    display: flex;
    align-items: center;
  }
  .laptop-container {
    display: none;
  }
  .counter-button p {
    font-weight: 600;
    margin-right: 1rem;
  }
  .countdown__description-section {
    width: 90%;
    margin: 3rem auto 0 auto;
    position: relative;
    z-index: 2;
  }
  .timer-container {
    height: 100%;
    position: relative;
    display: flex;
    align-items: flex-start;
    padding-top: 5rem;
  }
  .timer-numbers {
    display: flex;
    align-items: center;
  }
  @media (min-width: 768px) {
    .timer-container {
      padding-top: 30rem;
    }
    .countdown-container {
      grid-template-columns: 70% 1fr;
    }
    .countdown {
      font-size: 49.374rem;
    }
    .heading {
      font-size: 61.45px;
    }
    .description {
      font-size: 21.2727px;
    }
    .rotate {
      transform-origin: 110% 90%;
    }
    .laptop-image {
      bottom: 0;
      left: -5rem;

      transform: translateX(-12rem);
      max-height: 100%;
      max-width: 100%;
      animation: bounce 5s linear infinite;
    }
    .laptop-container {
      height: 28rem;
      /* width: 741px; */
      position: absolute;
      bottom: 0;
      z-index: 5;
      transform: translateY(25rem);
      display: block;
    }
  }

  @-webkit-keyframes bounce {
    0% {
      transform: translateY(-5px);
    }
    50% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(-5px);
    }
  }
  @keyframes bounce {
    0% {
      transform: translateY(-5px);
    }
    50% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(-5px);
    }
  }
</style>
