<template>
  <div class="first-container">
    <div
      class="
        container
        mx-auto
        calculator-container
        grid
        lg:grid-cols-2
        gap-8
        lg:gap-4
      "
    >
      <div class="content-section">
        <div class="content">
          <h4 class="content-heading">
            Business is hard, pricing shouldn’t be
          </h4>
          <p class="content-description">
            You don’t have to be good at math to understand our pricing. It's
            simply 5%.
          </p>
        </div>
      </div>
      <div class="calculator-section">
        <div class="calculator-body">
          <p class="calculator-heading">If an item you sell costs</p>
          <input
            type="number"
            class="calculator-input"
            placeholder="00.00"
            v-model="amount"
          />
          <p class="calculator-heading mt-5">
            Your customer will pay an inital amount of
          </p>
          <div class="customer-pay">
            <p class="price text-white">{{ customerAmount | formatMoney }}</p>
          </div>
          <div class="mt-6 price-container grid md:grid-cols-2">
            <div>
              <p class="calculator-heading mt-5">We will settle you</p>
              <p class="price text-white">
                {{ merchantAmount | formatMoney }}
              </p>
            </div>
            <div>
              <p class="calculator-heading mt-5">Zilla's fee is</p>
              <p class="price text-white">
                {{ zillaAmount | formatMoney }}
              </p>
            </div>
          </div>
          <div class="benefits">
            <div
              class="benefit-item"
              v-for="benefit in benefits"
              :key="benefit"
            >
              <div class="benefit-body">
                <p>{{ benefit }}</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="14"
                  fill="none"
                >
                  <path
                    d="M6 10.6701 1.83 6.50009l-1.42 1.41L6 13.5001 18 1.50009l-1.41-1.41L6 10.6701Z"
                    fill="#10DF88"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data: () => ({
      benefits: [
        " We only charge you when you make a sale.",
        "Seamless settlement within 24 hours",
        "Zero integration fee",
        "Zero maintenance fee",
        "Zero subscription fee",
      ],
      amount: 1000,
    }),
    computed: {
      customerAmount() {
        return (25 / 100) * this.amount;
      },
      merchantAmount() {
        return this.amount - this.amount * 0.05;
      },
      zillaAmount() {
        return this.amount * 0.05;
      },
    },
  };
</script>
<style scoped>
  .first-container {
    background-image: url("https://res.cloudinary.com/zillaafrica/image/upload/v1649266108/website/Rectangle_1236_sj9bty.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 4.063rem 0rem;
  }
  .calculator-container {
    /* background: rgba(255, 255, 255, 0.5); */
    /* opacity: 0.5; */
    /* backdrop-filter: blur(300px); */
    min-height: 30rem;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 2rem;
  }
  .content-section {
    display: grid;
    place-items: center;
  }
  .content-heading {
    font-size: 2.3rem;
    color: #1e1452;
    line-height: 120%;
    font-weight: 800;
    text-align: center;
  }
  .content-description {
    font-size: 1rem;
    color: #6b6873;
    text-align: center;
    line-height: 150%;
    font-weight: 400;
  }
  .calculator-body {
    background-color: #1e1452;
    border-radius: 1rem;
    padding: 2.5rem 2rem;
  }
  .customer-pay {
    overflow: auto;
  }
  .calculator-heading {
    color: #c2c0c7;
    margin-top: 2rem;
  }
  .calculator-input {
    width: 100%;
    border-radius: 1rem;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    margin-top: 8px;
    outline: none;
    color: white;
    border: none;
    outline: none;
    font-size: 2rem;
  }
  .price {
    font-size: 2.25rem;
    color: white;
  }
  .benefits {
    margin-top: 2.5rem;
  }
  .benefit-item {
    padding: 1rem 0;
    border-bottom: 0.5px solid #6956c8;
  }
  .benefit-item p {
    color: white;
    font-size: 14px;
  }
  .price-container {
    display: block;
    overflow: auto;
  }
  .benefit-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media (min-width: 768px) {
    .calculator-container {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      padding: 1rem;
    }
    .first-container {
      padding: 6.063rem 2rem;
    }
    .content-heading {
      font-size: 4.54rem;
      text-align: left;
    }
    .content-description {
      font-size: 1.875rem;
      color: #6b6873;
      text-align: left;
      font-weight: 400;
    }
    .calculator-input {
      font-size: 2.875rem;
    }
    .price-container {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
</style>
