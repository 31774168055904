import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/outsideClick";
import "./plugins/filter";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// import VueMeta from 'vue-meta'

const firebaseConfig = {
  apiKey: "AIzaSyB6Xk5fV_TRMtycS2MgbdSI_wBN7RCDDO4",
  authDomain: "zilla-68ac3.firebaseapp.com",
  projectId: "zilla-68ac3",
  storageBucket: "zilla-68ac3.appspot.com",
  messagingSenderId: "316326808025",
  appId: "1:316326808025:web:e9d46b9b8003214050f1c8",
  measurementId: "G-3JQK0RF637",
};

// Initialize Firebase
initializeApp(firebaseConfig);
getAnalytics();
Vue.config.productionTip = false;
Vue.prototype.$analytics = getAnalytics();
// Vue.use(VueMeta, {
//   keyName:'head',
// })

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
